
import { defineComponent, computed } from 'vue'
import { v4 as uuidv4 } from 'uuid'

import MazSpinner from '@web/components/maz/MazSpinner.vue'
/**
 * > Simple button component
 */
export default defineComponent({
  name: 'MazBtn',
  components: {
    MazSpinner
  },
  inheritAttrs: false,
  props: {
    // is the id of the button
    id: { type: String, default: null },
    // is color type (`primary` / `secondary` / `third` / `success` / `danger` / `grey` / `info` / `warning` / `light` / `dark` / `default` / `white` / `black`)
    color: {
      type: String,
      default: 'primary'
    },
    // is the button type (button, submit or something else)
    type: { type: String, default: 'button' },
    // button size (`xl`, `lg` / `md` / `sm` / `mini`)
    size: { type: String, default: 'md' },
    // is a `boolean` to show the loader & disable it
    loading: { type: Boolean, default: false },
    // is a `boolean` to disable the button
    disabled: { type: Boolean, default: false },
    // apply the outline style
    outline: { type: Boolean, default: false },
    // apply the rounded style
    rounded: { type: Boolean, default: false },
    // apply the fab style
    fab: { type: Boolean, default: false },
    // apply the focus style
    active: { type: Boolean, default: false },
    // take 100% of the width
    block: { type: Boolean, default: false },
    // remove shadow/elevation
    noShadow: { type: Boolean, default: false },
    // should be a [material icon](https://material.io/resources/icons/) name (usefull with fab buttons)
    iconName: { type: String, default: null },
    // should be a [material icon](https://material.io/resources/icons/) name
    leftIconName: { type: String, default: null },
    // should be a [material icon](https://material.io/resources/icons/) name
    rightIconName: { type: String, default: null },
    // align text to left (for block button)
    justifyStart: { type: Boolean, default: false },
    // align text to right (for block button)
    justifyEnd: { type: Boolean, default: false }
  },
  emits: ['click', 'mouseenter', 'mouseleave', 'focus', 'blur'],
  setup (props, ctx) {
    const uniqueId = uuidv4()

    const componentType = computed(() => {
      const { href, to } = ctx.attrs
      if (href) return 'a'
      else if (to) return 'router-link'
      return 'button'
    })
    const isLink = computed(() => componentType.value === 'a')
    const isDisabled = computed(() => props.loading || props.disabled)
    const classes = computed(() => {
      return [
        ...(props.color ? [`maz-btn--${props.color}`] : [null]),
        ...(props.size ? [`maz-btn--${props.size}`] : [null]),
        ...(props.outline ? ['maz-btn--outline'] : [null]),
        ...(props.rounded ? ['maz-btn--rounded'] : [null]),
        ...(props.block ? ['maz-btn--block'] : [null]),
        ...(props.fab ? ['maz-btn--fab'] : [null]),
        ...(props.active ? ['maz-active'] : [null]),
        ...(props.noShadow ? ['maz-no-shadow'] : [null]),
        ...(hasLeftIcon() ? ['maz-btn--icon--left'] : [null]),
        ...(hasRightIcon() ? ['maz-btn--icon--right'] : [null]),
        ...(hasIcon() ? ['maz-btn--icon'] : [null]),
        ...(!hasSlotDefault() ? ['maz-btn--no-text'] : [null])
      ]
    })

    const textClasses = computed(() => {
      return [
        ...(props.justifyStart ? ['maz-justify-start'] : [null]),
        ...(props.justifyEnd ? ['maz-justify-end'] : [null]),
        ...(!props.justifyStart && !props.justifyEnd ? ['maz-justify-center'] : [null])
      ]
    })

    function hasSlotDefault () {
      return ctx.slots.default
    }
    function hasIcon () {
      return hasLeftIcon() || hasRightIcon() || hasMainIcon()
    }
    function hasMainIcon () {
      return props.iconName || ctx.slots.icon
    }
    function hasLeftIcon () {
      return props.leftIconName || ctx.slots['icon-left']
    }
    function hasRightIcon () {
      return props.rightIconName || ctx.slots['icon-right']
    }
    function handleClick (e) {
      // return click event
      ctx.emit('click', e)
    }
    function emitMouseEnter (e) {
      // return mouseenter event
      ctx.emit('mouseenter', e)
    }
    function emitMouseLeave (e) {
      // return mouseleave event
      ctx.emit('mouseleave', e)
    }
    function emitFocus (e) {
      // return focus event
      ctx.emit('focus', e)
    }
    function emitBlur (e) {
      // return blur event
      ctx.emit('blur', e)
    }

    return {
      uniqueId,
      componentType,
      isLink,
      isDisabled,
      classes,
      textClasses,

      hasSlotDefault,
      hasIcon,
      hasMainIcon,
      hasLeftIcon,
      hasRightIcon,
      handleClick,
      emitMouseEnter,
      emitMouseLeave,
      emitFocus,
      emitBlur
    }
  }
})
