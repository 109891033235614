
import { defineComponent, computed } from 'vue'

export default defineComponent({
  name: 'MazSpinner',
  props: {
    size: { type: Number, default: 40 },
    dark: { type: Boolean, default: false },
    color: { type: String, default: 'primary' }
  },
  setup (props) {
    const fillColorClass = computed(() => `maz-fill-${props.color}`)

    return {
      fillColorClass
    }
  }
})
