import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "material-icons" }
const _hoisted_2 = { class: "material-icons" }
const _hoisted_3 = {
  key: 2,
  class: "maz-btn__spinner maz-flex maz-flex-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MazSpinner = _resolveComponent("MazSpinner")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.componentType), _mergeProps({ id: _ctx.uniqueId }, _ctx.$attrs, {
    class: ["maz-base-component maz-btn maz-inline-flex", [
      _ctx.classes,
      {
        'maz-text-hidden': _ctx.loading
      }
    ]],
    type: _ctx.isLink ? null : _ctx.type,
    disabled: _ctx.isLink ? null : _ctx.isDisabled,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleClick($event))),
    onMouseenter: _cache[1] || (_cache[1] = ($event: any) => (_ctx.emitMouseEnter($event))),
    onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.emitMouseLeave($event))),
    onFocus: _cache[3] || (_cache[3] = ($event: any) => (_ctx.emitFocus($event))),
    onBlur: _cache[4] || (_cache[4] = ($event: any) => (_ctx.emitBlur($event)))
  }), {
    default: _withCtx(() => [
      (_ctx.hasLeftIcon() || _ctx.hasMainIcon())
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["maz-flex maz-flex-center maz-btn__icon-left", {
        'maz-mr-2': !_ctx.fab && _ctx.hasSlotDefault()
      }])
          }, [
            _renderSlot(_ctx.$slots, `icon-left` || `icon`, {}, () => [
              _createElementVNode("i", _hoisted_1, _toDisplayString(_ctx.leftIconName || _ctx.iconName), 1)
            ])
          ], 2))
        : _createCommentVNode("", true),
      _createElementVNode("span", {
        class: _normalizeClass([[_ctx.textClasses, {'maz-flex-1': _ctx.hasSlotDefault()}], "maz-flex maz-align-center maz-h-100 maz-overflow-hidden"])
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 2),
      (_ctx.hasRightIcon())
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["maz-flex maz-flex-center maz-btn__icon-right", {
        'maz-ml-2': !_ctx.fab && _ctx.hasSlotDefault()
      }])
          }, [
            _renderSlot(_ctx.$slots, `icon-right`, {}, () => [
              _createElementVNode("i", _hoisted_2, _toDisplayString(_ctx.rightIconName), 1)
            ])
          ], 2))
        : _createCommentVNode("", true),
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_MazSpinner, {
              size: 25,
              color: _ctx.color
            }, null, 8, ["color"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 16, ["id", "class", "type", "disabled"]))
}