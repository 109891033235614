
import { defineComponent, ref, computed } from 'vue'
import { v4 as uuidv4 } from 'uuid'

const debounce = (func, delay) => {
  let debounceTimer
  return function () {
    const args = arguments
    clearTimeout(debounceTimer)
    debounceTimer = setTimeout(() => func(args), delay)
  }
}
const DEBOUNCE = 500

export default defineComponent({
  name: 'MazInput',
  props: {
    // value of the input
    modelValue: {
      type: [String, Number],
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      validator: (prop: any) => ['string', 'number'].includes(typeof prop) || prop === null,
      default: null
    },
    // input id
    id: { type: String, default: null },
    // value of the input
    placeholder: { type: String, default: 'Enter text' },
    // replace the label if is present
    hint: { type: String, default: null },
    // input size (`'lg'` / `'sm'`)
    size: { type: String, default: null },
    // is the input size (`text` or `number`)
    type: { type: String, default: 'text' },
    // should be a [material icon](https://material.io/resources/icons/) name
    leftIconName: { type: String, default: null },
    // should be a [material icon](https://material.io/resources/icons/) name
    rightIconName: { type: String, default: null },
    // When is `true` the input has the error style
    error: { type: Boolean, default: false },
    // When is `true` the input has the warning style
    warning: { type: Boolean, default: false },
    // When is `true` the input is disable
    disabled: { type: Boolean, default: false },
    // When is `true` the input has the dark theme
    dark: { type: Boolean, default: false },
    // When is `true` the input is on readonly mode
    readonly: { type: Boolean, default: false },
    // When is `true` the input has the valid style
    success: { type: Boolean, default: false },
    // When is `true` the input become required & has the `*` symbol
    required: { type: Boolean, default: false },
    // When is `true` the input is a textarea
    textarea: { type: Boolean, default: false },
    // When is `true` the input has a progress bar animated
    loading: { type: Boolean, default: false },
    // When is `true` the input can be clear with a button on the right
    clearable: { type: Boolean, default: false },
    // When is `true` the input has not label (top placeholder when value is not empty)
    noLabel: { type: Boolean, default: false },
    // When is `true` and is `required`, the `*` symbol is not showing
    noRequiredSymbol: { type: Boolean, default: false },
    // force focus style input
    focus: { type: Boolean, default: false },
    // color name in basic colors
    color: { type: String, default: 'primary' },
    // Add a debounce of 500ms to emit the value
    debounce: { type: Boolean, default: false }
  },
  emits: ['input', 'focus', 'blur', 'paste', 'change', 'clear', 'keyup', 'keydown', 'click', 'update:modelValue'],
  setup (props, ctx) {
    const uniqueId = uuidv4()
    const isFocus = ref(false)
    const showPassword = ref(false)
    const MazInput = ref<HTMLInputElement | null>(null)
    const parent = ref<HTMLInputElement | null>(null)
    const label = ref<HTMLInputElement | null>(null)

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const inputValue = computed<any>({
      get () {
        return props.modelValue
      },
      set (value: string) {
        const valueToEmit = hasNumberType.value
          ? !value ? 0 : parseInt(value)
          : value
        emitValue(valueToEmit)
      }
    })
    const placeholderValue = computed(() => {
      if (props.required && props.placeholder && !props.noRequiredSymbol) {
        return `${props.placeholder} *`
      }
      return props.placeholder
    })
    const hintValue = computed(() => {
      if (props.required && props.hint) {
        return `${props.hint} *`
      }
      return props.hint
    })
    const hasNumberType = computed(() => props.type === 'number')
    const hasLabel = computed(() => !props.noLabel)
    const getType = computed(() => showPassword.value ? 'text' : props.type)
    const hasPasswordBtn = computed(() => props.type === 'password' && inputValue.value)
    const hasClearBtn = computed(() => props.clearable && inputValue.value && !props.textarea)
    const leftNumberIcon = computed(() => {
      const array = [
        !!hasRightIcon(),
        !!hasClearBtn.value,
        !!hasPasswordBtn.value
      ]
      return array.filter(a => a).length
    })

    const debounceValue = debounce(function (value) {
      // return the input value (in `@input` or `v-model`)
      // @arg input
      ctx.emit('update:modelValue', value)
    }, DEBOUNCE)

    function emitValue (value) {
      if (props.debounce) return debounceValue()
      ctx.emit('update:modelValue', value)
    }

    function hasLeftIcon () {
      return props.leftIconName || ctx.slots['icon-left']
    }

    function hasRightIcon () {
      return props.rightIconName || ctx.slots['icon-right']
    }

    function focusInput () {
      MazInput.value?.focus()
    }

    function onFocus (e) {
      // sent the focus event
      // @arg event
      ctx.emit('focus', e)
      isFocus.value = true
    }

    function onBlur (e) {
      // sent the blur event
      // @arg event
      ctx.emit('blur', e)
      isFocus.value = false
    }

    function onPaste (e) {
      // sent when text is past in the textfield
      // @arg event
      ctx.emit('paste', e)
    }

    function onChange (e) {
      // sent on input change
      // @arg event
      ctx.emit('change', e)
    }

    function clear () {
      ctx.emit(
        'update:modelValue',
        hasNumberType.value ? 0 : ''
      )
      // sent when the input is clear
      ctx.emit('clear')
    }

    function keyUp (e) {
      // sent the keyup event
      // @arg event
      ctx.emit('keyup', e)
    }
    function keyDown (e) {
      // sent the keydown event
      // @arg event
      ctx.emit('keydown', e)
    }

    return {
      uniqueId,
      isFocus,
      showPassword,
      MazInput,
      parent,
      label,
      inputValue,
      placeholderValue,
      hintValue,
      hasNumberType,
      hasLabel,
      getType,
      hasPasswordBtn,
      hasClearBtn,
      leftNumberIcon,
      debounceValue,
      emitValue,
      hasLeftIcon,
      hasRightIcon,
      focusInput,
      onFocus,
      onBlur,
      onPaste,
      onChange,
      clear,
      keyUp,
      keyDown
    }
  }
})
